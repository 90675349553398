import hash from 'blueimp-md5'
import { _, createSchema } from 'tiny-schema-validator'
import { families, optionsMap } from './configurator-options'

const conditions = Object.keys(optionsMap.condition)
const heatExchangers = Object.keys(optionsMap.heatExchangers)

export const configuratorDefaults = {
  productType: null,
  previouslySelectedProductType: null,
  family: {},
  condition: 'drySystem',
  priority: 'heating',
  productConfig: null,
  heatExchangers: heatExchangers.reduce((acc, curr) => {
    acc[curr] = true
    return acc
  }, {}),
  air: {
    inputTemperature: '25.0',
    relativeHumidity: '50.0',
    inputTemperatureCool: '25.0',
    relativeHumidityCool: '50.0',
    inputTemperatureHot: '15.0',
    relativeHumidityHot: '30.0',
    altitude: '0',
    externalStaticPressure: '0'
  },
  fluid: {
    fluidType: null,
    condensingTemperature: '40.0',
    gasTemperature: '75.0',
    subCoolingDeltaTemperature: '3.0',
    inputTemperature: '40.0',
    inputTemperatureCool: '40.0',
    inputTemperatureHot: '1.0',
    outputTemperature: '35.0',
    outputTemperatureCool: '35.0',
    outputTemperatureHot: '5.0'
  },
  duty: {
    doubleCircuitOption: false,
    circuitCapacity: '100.0',
    maxLiquidPressureDrop: '80.0',
    secondCircuitCapacity: null,
    waterFlow: null,
    tolerance: '10',
    capacityMargin: '0',
    surfaceMargin: '0'
  },
  dimensions: {
    maxLength: '',
    maxHeight: '',
    maxDepth: '',
    maxWeight: ''
  },
  ventilationNoise: {
    soundPowerLw: '',
    soundPressureLp: '',
    distance: '10'
  },
  ventilationTech: {
    AC: true,
    EC: true,
    highEfficiency: true
  },
  ventilationPowerSource: '-',
  maxPowerConsumption: '',
  energyEfficiencyClass: '-',
  unitSystem: 'SI',
  tubeSeriesNumber: '-',
  // Single product configuration
  fanPercentage: 100,
  finSpacing: null,
  finThickness: null,
  // UI flags
  ui: {
    searchMode: 'bytype',
    range: {},
    showForm: false,
    allowSubmit: false,
    showTubeSeriesNumber: true,
    condition: {
      show: conditions.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    },
    heatExchangers: {
      show: heatExchangers.reduce((acc, curr) => {
        acc[curr] = true
        return acc
      }, {})
    },
    air: {
      show: {
        cool: false,
        hot: false
      }
    },
    fluid: {
      show: {
        gas: false,
        cool: false,
        hot: false,
        compatibleRefrigerantsOnly: false
      }
    },
    duty: {
      show: {
        circuitCapacity: true,
        doubleCircuitOption: false,
        maxLiquidPressureDrop: false,
        waterFlow: false
      },
      disableMaxLiquidPressureDrop: false,
      disableSurfaceMargin: false
    },
    showPriority: false,
    hasAdvancedFiltersDiff: false
  },
  errors: {}
}

configuratorDefaults.version = hash(JSON.stringify(configuratorDefaults))

const familyValidators = [...new Set(Object.values(families).map(value => Object.values(value)).flat(2))]
  .reduce((acc, curr) => {
    acc[curr] = _.boolean({ optional: true })
    return acc
  }, {})

const rangeValidators = [...new Set(Object.values(families).map(value => Object.keys(value)).flat())]
  .reduce((acc, curr) => {
    acc[curr] = _.boolean({ optional: true })
    return acc
  }, {})

export const ConfiguratorValidator = createSchema({
  productType: _.string({ optional: true }),
  previouslySelectedProductType: _.string({ optional: true }),
  family: _.record(familyValidators),
  productConfig: _.record({
    id: _.number(),
    familyId: _.number(),
    familyName: _.string(),
    familyCode: _.string(),
    name: _.string(),
    unitSystem: _.string(),
    productType: _.string(),
    adiabaticSystem: _.record(conditions.reduce((acc, curr) => {
      acc[curr] = _.boolean()
      return acc
    }, {})),
    fanMainType: _.string(),
    tubeSeriesNumber: _.number({ optional: true }),
    maxTubeSeriesNumber: _.number(),
    finData: _.listof(_.record({
      id: _.number(),
      pitch: _.number(),
      thickness: _.number()
    })),
    finSpacing: _.number(),
    finThickness: _.number(),
    showMachine: _.boolean({ optional: true })
  }, { optional: true }),
  condition: _.string(),
  priority: _.string(),
  heatExchangers: _.record({
    ovalTube: _.boolean(),
    roundTube: _.boolean(),
    microchannel: _.boolean()
  }),
  air: _.record({
    inputTemperature: _.string(),
    relativeHumidity: _.string(),
    inputTemperatureCool: _.string(),
    relativeHumidityCool: _.string(),
    inputTemperatureHot: _.string(),
    relativeHumidityHot: _.string(),
    altitude: _.string(),
    externalStaticPressure: _.string()
  }),
  fluid: _.record({
    fluidType: _.number({ optional: true }),
    condensingTemperature: _.string(),
    gasTemperature: _.string(),
    subCoolingDeltaTemperature: _.string(),
    inputTemperature: _.string(),
    inputTemperatureCool: _.string(),
    inputTemperatureHot: _.string(),
    outputTemperature: _.string(),
    outputTemperatureCool: _.string(),
    outputTemperatureHot: _.string()
  }),
  duty: _.record({
    doubleCircuitOption: _.boolean(),
    circuitCapacity: _.string(),
    maxLiquidPressureDrop: _.string({ optional: true }),
    secondCircuitCapacity: _.string({ optional: true }),
    waterFlow: _.string({ optional: true }),
    tolerance: _.string(),
    capacityMargin: _.string(),
    surfaceMargin: _.string()
  }),
  dimensions: _.record({
    maxLength: _.string(),
    maxHeight: _.string(),
    maxDepth: _.string(),
    maxWeight: _.string()
  }),
  ventilationNoise: _.record({
    soundPowerLw: _.string(),
    soundPressureLp: _.string(),
    distance: _.string()
  }),
  ventilationTech: _.record({
    AC: _.boolean(),
    EC: _.boolean(),
    highEfficiency: _.boolean()
  }),
  ventilationPowerSource: _.string(),
  maxPowerConsumption: _.string(),
  energyEfficiencyClass: _.string(),
  tubeSeriesNumber: _.string(),
  fanPercentage: _.number(),
  finSpacing: _.string({ optional: true }),
  finThickness: _.string({ optional: true }),
  unitSystem: _.string(),
  ui: _.record({
    searchMode: _.string(),
    range: _.record(rangeValidators),
    showForm: _.boolean(),
    allowSubmit: _.boolean(),
    showTubeSeriesNumber: _.boolean(),
    condition: _.record({
      show: _.record(conditions.reduce((acc, curr) => {
        acc[curr] = _.boolean()
        return acc
      }, {}))
    }),
    heatExchangers: _.record({
      show: _.record({
        ovalTube: _.boolean(),
        roundTube: _.boolean(),
        microchannel: _.boolean()
      })
    }),
    air: _.record({
      show: _.record({
        cool: _.boolean(),
        hot: _.boolean()
      })
    }),
    fluid: _.record({
      show: _.record({
        gas: _.boolean(),
        cool: _.boolean(),
        hot: _.boolean(),
        compatibleRefrigerantsOnly: _.boolean()
      })
    }),
    duty: _.record({
      show: _.record({
        circuitCapacity: _.boolean(),
        doubleCircuitOption: _.boolean(),
        maxLiquidPressureDrop: _.boolean(),
        waterFlow: _.boolean()
      }),
      disableMaxLiquidPressureDrop: _.boolean(),
      disableSurfaceMargin: _.boolean()
    }),
    showPriority: _.boolean(),
    hasAdvancedFiltersDiff: _.boolean()
  }),
  key: _.number(),
  version: _.string()
})
